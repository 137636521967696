import moment from "moment";

export const formatDate = (d) => d.toLocaleDateString("de");

export const formatMixin = {
  filters: {
    date(d) {
      if (!d) {
        return null;
      }
      d = new Date(Date.parse(d));
      return formatDate(d);
    },

    time(d) {
      if (!d) {
        return null;
      }
      let time = moment(d).format('HH:mm');

      if (time != "00:00") {
        return time;
      }

      return null;
    },

    datetime(d) {
      if (!d) {
        return null;
      }

      d = new Date(Date.parse(d));
      let time = d.toLocaleTimeString("de", {
        hour: "2-digit",
        minute: "2-digit",
      });
      if (time != "00:00") {
        return d.toLocaleDateString("de") + " " + time;
      }
      return d.toLocaleDateString("de");
    },

    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },

    uppercase(value) {
      if (!value) return "";
      value = value.toString();
      return value.toUpperCase();
    },

    lineBreaks(str) {
      return str.replace(/\r?\n/gim, "<br/>");
    },

    numberWithCommas(x) {
      if (!x) return "";
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    fallback(txt, def) {
      if (txt) {
        return txt;
      }
      return def;
    },
  },

  methods: {
    getDate: function(d) {
      if (!d) {
        return null;
      }
      d = new Date(Date.parse(d));
      return formatDate(d);
    },
    getDateTime: function(d) {
      if (!d) {
        return null;
      }

      d = new Date(Date.parse(d));
      let time = d.toLocaleTimeString("de", {
        hour: "2-digit",
        minute: "2-digit",
      });
      if (time != "00:00") {
        return d.toLocaleDateString("de") + " " + time;
      }
      return d.toLocaleDateString("de");
    },
  },
};

const getValueByPath = (obj, path) => {
  const value = path.split(".").reduce((o, i) => o[i], obj);
  return value;
};

export const sortMixin = {
  methods: {
    idSorter(key) {
      return (a, b, isAsc) => {
        const va = parseFloat(getValueByPath(a, key));
        const vb = parseFloat(getValueByPath(b, key));
        return isAsc ? (va > vb ? 1 : -1) : va > vb ? -1 : 1;
      };
    },

    boolSorter(key) {
      return (a, b, isAsc) => {
        const va = 1 & getValueByPath(a, key);
        const vb = 1 & getValueByPath(b, key);
        return isAsc ? (va > vb ? 1 : -1) : va > vb ? -1 : 1;
      };
    },
  },
};

export const formMixin = {
  data: function() {
    return {
      rules: {
        required: [(v) => !!v || this.$i18n.t("common.form.required")],
        email: [(v) => !!v || this.$i18n.t("common.form.requiredEmail"), (v) => /(^$|^.*@.*\..*$)/.test(v) || this.$i18n.t("common.form.validEmail")],
        emailoptional: [(v) => /(^$|^.*@.*\..*$|null)/.test(v||"") || this.$i18n.t("common.form.validEmail")],
        pwdvalidate: [
          (v) => !!v || this.$i18n.t("common.form.requiredEmail"), 
          (v) => v == this.pwd || this.$i18n.t("common.form.identicalEmail")
        ],
      },
      fPercent: {
        currency: {
          suffix: " %",
        },
        valuerange: {
          min: 0,
          max: 100,
        },
      },
      fEuro: {
        currency: "EUR",
      },
    };
  },

  mounted: function() {
    /*
    this.$nextTick(function() {
      if (this.$refs && this.$refs.firstfield) {
        this.$refs.firstfield.focus();
      }
    });
    */
  },

  methods: {
    focusOnFirstField: function() {
      if (this.$refs && this.$refs.firstfield) {
        this.$refs.firstfield.focus();
      }
    },
    scrollToTop: function() {
      setTimeout(() => {
        this.$vuetify.goTo(".top", {
        duration: 500,
        offset: 10,
        easing: "easeInOutCubic",
      });
      }, 100);
    },
  },
};

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export const richMixin = {
  data: function() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ["bold", "italic", "link", "undo", "redo"],
      },
    };
  },
};

export const listMixin = {
  data: function() {
    return {};
  },
  methods: {
    groupBy: function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    updateItemList: function(list, o) {
      if (o) {
        if (o.id) {
          let oIndx = list.findIndex((obj) => obj.id == o.id);
          if (oIndx > -1) {
            list.splice(oIndx, 1, o);
          } else {
            list.push(o);
          }
        } else {
          list.push(o);
        }
      }
    },
  },
};
